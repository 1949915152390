html {
  zoom: 70%;
}

.App {
  height: 100%;
  min-height: 100vh;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}
