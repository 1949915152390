@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html {
  overflow-x: hidden;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  color: black;
  font-weight: bold;
  overflow-x: hidden;
  overflow: hidden;
  position: relative;
  display: table;
  font-family: 'Open Sans', sans-serif;
}

/* top-bar */

p {
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}

select,
button {
  line-height: initial !important;
}

ul {
  margin-top: 1em !important;
}

input:focus-visible,
select:focus-visible {
  outline: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out !important;
}

.fade {
  transition: opacity .15s linear;
}

.ContentWrapper {
  min-height: 100vh;
  width: calc(100% - 270px);
  margin-left: auto;
  margin-bottom: 60px;
}

.ContentWrapper-SideBarClosed {
  width: calc(100% - 60px);
}

.modal-header {
  border-bottom: none;
}


.sideBar-container {
  display: flex;
  height: 100%;
  overflow: scroll initial;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.11);
}

.sideBar-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.sideBar-footer a {
  width: 100%;
  height: 60px;
  display: flex;
  padding-left: 30px;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  gap: 15px;
  color: #000000;
  text-decoration: none;
  transition: 0.2s;
}

.sideBar-footer-closed a {
  justify-content: center;
  padding-left: 0px;
}

.sideBar-footer-closed a span {
  display: none;
}

.sideBar-active {
  background-color: #D2E2FD;
  position: relative;
}

.sideBar-active::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 8px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #18202D;
}

.pro-sidebar.toggled {
  width: 60px !important;
  min-width: 60px !important;
}

.sideBar-container-Open .pro-sidebar {
  width: 270px !important;
}

.sideBar-logo {
  width: 100%;
}

.loadingContainer {
  position: fixed;
  top: 50px;
  left: 50%;
  z-index: 100;
}

.loadingContainer svg g {
  stroke: #000000;
  stroke-width: 5;
}

/* .navBar {
  background-color: #1d2635;
  padding: 5px;
  height: 90px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.nav-ham-container .ham {
  padding: 15px;
  outline: 2px solid rgb(191, 191, 191);
  border-radius: 5px;
  cursor: pointer;
}

.nav-ham-container .ham div {
  width: 26px;.Nav
  height: 3px;
  background-color: rgb(202, 202, 202);
  margin-bottom: 4px;
}

.nav-ham-container {
  display: inline-block;
  float: right;
  display: none;
}

.dropdown-toggle::after {
  display: none !important;
} */

/* end-top-bar */

/* page header */

.pageHeader {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
  padding: 15px;
}

.pageHeader .pageHeader-icon {
  font-size: 35px;
  margin-top: 5px;
}

.pageHeader .pageHeader-title {
  display: flex;
  color: #000000;
  margin-left: 20px;
}

.pageHeader .pageHeader-title-withButton .pageHeader-title-withButton-Container {
  display: flex;
}

.pageHeader .pageHeader-title h1 {
  font-size: 35px;
}

.pageHeader .pageHeader-title p {
  font-weight: 400;
  font-size: 15px;
}

.pageHeader .pageHeader-title img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 9px;
}

/* end page header */

/* nav menu */

.user-card {
  color: white;
  width: 210px;
  border-radius: 10px;
  display: inline-block;
}

.user-img-top-bar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
  float: left;
}

.user-name {
  color: white;
  font-size: 12px;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.user-name p {
  margin-left: 2% !important;
}

.user-card-nameDiv {
  color: black;
  width: 150px;
}

.nav-menu {
  list-style: none;
  display: inline-block;
  padding: 10px;
}

.nav-menu .option {
  text-decoration: none;
  color: white;
  font-size: 15px;
  padding: 20px;
  color: rgb(170, 170, 170);
}

.nav-menu .option:hover {
  color: rgb(212, 212, 212);
}

/* user-card-end */

/* dashboard */
.dashboard {
  /* margin: auto;
  height: auto;
  width: 90%;
  padding: 15px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 25px;
}

.dashboard-buttonsSection {
  display: flex;
  justify-content: space-around;
}

.dashboard-buttonsSection button {
  height: 70px;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #D2E2FD;
  font-size: 20px;
  font-weight: 700;
}

.dashboard-buttonsSection button:nth-of-type(1) {
  background-color: #86B3FC;
}

.dashboard-buttonsSection button div {
  height: 100%;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dashboard-buttonsSection button div .separator {
  border: thin solid #3840471d;
  border-radius: 10px;
  height: 50%;
  display: inline-block;
}

.userRecentAccess-Container {
  margin-top: 20px;
  width: 100%;
}

.userOperations-recent {
  width: 100%;
}

.userTargets-recent {
  width: 100%;
}

.userOperations-recent h4 {
  margin-left: 20px;
  font-weight: 400;
}

.userTargets-recent h4 {
  margin-left: 20px;
  font-weight: 400;
  width: 90%;
}

.recentPosts-container {
  margin-left: 20px;
  width: 100%;
  position: relative;
}

.recentPosts-container .recentPostsCard-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.recentPosts-container .recentPostsCard-container .postCard {
  width: 49%;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
}

.recentPosts-container .recentPostsCard-container .postCard .postCard-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.recentPosts-container .recentPostsCard-container .postCard img {
  width: 75px;
  height: 75px;
  border-radius: 100px;
  object-fit: cover;
}

.recentPosts-container .recentPostsCard-container .postCard .postCard-content .postCard-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recentPosts-container .recentPostsCard-container .postCard .postCard-content .postCard-text h5 {
  font-weight: 600;
  font-size: 17px;
  margin-top: 18px;
  color: #1d2635;
}

.recentPosts-container .recentPostsCard-container .postCard .postCard-buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.recentPosts-container .recentPostsCard-container .postCard .postCard-buttonContainer .postCard-button {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}


.recentTargets-container {
  margin-left: 20px;
  width: 100%;
  position: relative;
}

.recentTargets-container .recentTargetsCard-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.recentTargets-container .recentTargetsCard-container .targetCard {
  width: 49%;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.15);
}

.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.recentTargets-container .recentTargetsCard-container .targetCard img {
  width: 75px;
  height: 75px;
  border-radius: 100px;
  object-fit: cover;
}

.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-content .targetCard-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-content .targetCard-text h5 {
  font-weight: 600;
  font-size: 17px;
  margin-top: 18px;
  color: #1d2635;
}

.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-buttonContainer .targetCard-button {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transition: 0.2s;
}

.DashboardHr {
  display: none;
  margin-top: -10px;
  width: 100%;
}

/* end-dashboard */

.ConstRadius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: none;
}

/* operations */
.Operations {
  margin: auto;
  height: auto;
  width: 90%;
}

.SearchContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.SearchContainer .SearchContainer-select,
.SearchContainer .SearchContainer-search {
  width: 45%;
  display: flex;
  flex-wrap: nowrap;
}


.SearchContainer button,
input,
select {
  border: 1px #e0e0e0 solid;
  border-radius: 1px;
  font-size: 15px;
  white-space: nowrap;
}

.SearchContainer button {
  color: black;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.SearchContainer select {
  background-color: white;
  width: 15rem;
}

.targetsSearchContainer select {
  background-color: white;
  width: 26rem;
}

.SearchContainer input:focus {
  outline: none;
}

.SearchContainer .SearchInputContainer {
  position: relative;
}

.SearchContainer .SearchInputContainer .Search {
  width: 100%;
  border-radius: 100px;
  padding: 15px 20px;
  box-shadow: 2px 2px 9px -4px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 2px 2px 9px -4px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 2px 2px 9px -4px rgba(0, 0, 0, 0.42);
}

.SearchContainer .SearchInputContainer svg {
  position: absolute;
  right: 30px;
  bottom: 25%;
}

.SearchContainer .NewObject {
  border-radius: 5px;
  width: 9.5rem;
  background-color: #324ab2;
  color: white;
}

.SearchContainer .NewObject:hover {
  cursor: pointer;
}

.OperationsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.OperationCardContainer {
  border: #e0e0e0 1px solid;
  width: 49%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 25px;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.OperationCardContainer-OperationsPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.OperationCard-name {
  position: absolute;
  bottom: -17px;
  left: 0px;
  background-color: #324ab2;
  padding: 5px;
  font-size: 20px;
  border-top-right-radius: 3px;
  color: white;
}

.OperationCardContainer .OperationImg {
  width: 100%;
  height: 250px;
}

.OperationCardContainer .OperationImg img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.OperationDescription {
  padding: 5px;
}

.OperationCardContainerButton {
  width: 100%;
  background-color: #324ab2;
  border: none;
  color: white;
  padding: 10px;
  font-size: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.OperationCardContainerButton:hover {
  cursor: pointer;
}

.SearchContainer div:nth-of-type(2) {
  margin-left: 20px;
  margin-right: 20px;
}

.NewOperationButton {
  height: 60px;
  width: 35%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin-left: auto;
  transition: 0.2s;
}

.newOperation-TargetCard p {
  font-weight: 600;
  font-size: 22px;
  color: #202020;
}

.SearchContainer div {
  width: 100%;
}

.btn:first-child:hover,
:not(.btn-check)+.btn:hover {
  color: #000000;
}

.dropdown-toggle::after {
  display: none;
}

.SearchContainer div input,
.SearchContainer div select {
  width: 100% !important;
}

.SearchContainer div:nth-of-type(2) {
  margin-left: 0px;
}

/* end-operations */

/* operation card */

.OperationCard-container img {
  width: 100%;
  height: 200px;
  border-radius: 20px;
  margin-right: 15px;
  object-fit: contain;
}

.OperationCard-container #OperationCardContent-container {
  display: flex;
}

.OperationCard-container .OperationDescription {
  margin-right: auto;
}

.OperationCard-container .OperationDescription .OperationDescription-title {
  color: rgb(55, 55, 127);
  font-size: 20px;
}

.OperationCard-container .OperationDescription .OperationDescription-date {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.OperationCard-container .OperationDescription .OperationDescription-description {
  padding-top: 45px;
  width: 122%;
}

.OperationCardContainerStatus {
  width: 100%;
  display: flex;
  justify-content: center;
}

.OperationCardContainerStatus .OperationCardContainerStatus-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  margin: 30px 0px;
}

.OperationCardContainerStatus .OperationCardContainerStatus-container div {
  font-size: 20px;
}

.OperationCardDashboardContainerButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.OperationCardDashboardContainerButton .OperationCardDashboardContainerButton-button {
  height: 60px;
  width: 45%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transition: 0.2s;
}

/* end operation card */

/* operation card dashboard */

.OperationCardDashboard-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.OperationCardDashboard-container img {
  width: 40%;
  height: 200px;
  border-radius: 20px;
  margin-right: 15px;
  object-fit: contain;
}

.OperationCardDashboard-container .OperationDescription {
  margin-right: auto;
}

.OperationCardDashboard-container .OperationDescription .OperationDescription-title {
  color: rgb(55, 55, 127);
}

.OperationCardDashboard-container .OperationDescription .OperationDescription-date {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.OperationCardDashboard-container .OperationDescription .OperationDescription-description {
  padding-top: 45px;
}

.OperationCardDashboardContainerStatus {
  width: 100%;
  display: flex;
  justify-content: center;
}

.OperationCardDashboardContainerStatus .OperationCardDashboardContainerStatus-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  margin: 30px 0px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}

.OperationCardDashboardContainerStatus .OperationCardDashboardContainerStatus-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.OperationCardDashboardContainerStatus .OperationCardDashboardContainerStatus-container .separator {
  border: thin solid #38404719;
  border-radius: 10px;
  height: 80%;
  display: inline-block;
}

.OperationCardDashboardContainerButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.OperationCardDashboardContainerButton .OperationCardDashboardContainerButton-button {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

.OperationCardDashboard-container #UnknownLabel {
  position: absolute;
  right: 20px;
  top: 20px;
  color: gray;
}

/* end operation card dashboard */

/* targets */
.Targets {
  margin: auto;
  height: auto;
  width: 90%;
}

.TargetsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.TargetCardContainer {
  border: #e0e0e0 1px solid;
  float: left;
  width: 49%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 25px;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.TargetCardContainer .TargetCard-Container img {
  object-fit: contain;
  width: 100%;
  height: 250px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.TargetCard-Container #TargetCardContent-container {
  display: flex;
}

.TargetCard-Container .TargetDescription {
  margin-right: auto;
}

.TargetCard-Container .TargetDescription .TargetDescription-title {
  color: rgb(55, 55, 127);
  font-size: 20px;
}

.TargetCard-Container .TargetDescription .TargetDescription-date {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.TargetCard-Container .TargetDescription .TargetDescription-description {
  padding-top: 45px;
}

.TargetCardContainerStatus {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TargetCardContainerStatus .TargetCardContainerStatus-container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  margin: 30px 0px;
}

.OperationCardContainerStatus .OperationCardContainerStatus-container div {
  font-size: 20px;
}

.TargetCardDashboardContainerButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TargetCardDashboardContainerButton .TargetCardDashboardContainerButton-button {
  height: 60px;
  width: 45%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  transition: 0.2s;
}

/* end-targets */

/* targets dashboard */

.TargetCardDashboard-container {
  display: flex;
  flex-wrap: wrap;
}

.TargetCardDashboard-container img {
  width: 40%;
  height: 200px;
  border-radius: 20px;
  margin-right: 15px;
  object-fit: contain;
}

.TargetCardDashboard-container .TargetDescription {
  margin-right: auto;
}

.TargetCardDashboard-container .TargetDescription .TargetDescription-title {
  color: rgb(55, 55, 127);
}

.TargetCardDashboard-container .TargetDescription .TargetDescription-date {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.TargetCardDashboard-container .TargetDescription .TargetDescription-description {
  padding-top: 5px;
}

.TargetCardDashboardContainerStatus {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TargetCardDashboardContainerStatus .TargetCardDashboardContainerStatus-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  margin: 30px 0px;
  background-color: rgb(237, 237, 237);
  border-radius: 10px;
}

.TargetCardDashboardContainerStatus .TargetCardDashboardContainerStatus-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TargetCardDashboardContainerStatus .TargetCardDashboardContainerStatus-container .separator {
  border: thin solid #38404719;
  border-radius: 10px;
  height: 80%;
  display: inline-block;
}

.TargetCardDashboardContainerButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.TargetCardDashboardContainerButton .TargetCardDashboardContainerButton-button {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
}

/* end targets dashboard */

/* TargetProfile */
.TargetProfile {
  height: auto;
  width: 100%;
}

.TargetProfile .TargetProfileContent {
  width: 90%;
  margin: auto;
}

.TargetProfile .TargetProfileContent .TargetProfileCardContainer {
  width: 100%;
}

.TargetProfile .TargetProfileInfo {
  width: 100%;
  background-color: #000000;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.TargetProfile .TargetProfileHeader {
  height: 300px;
  display: flex;
  border: 2px solid #c7c7c767;
  border-radius: 10px;
}

.TargetProfile .TargetProfileInfo ul {
  list-style: none;
  font-weight: 500;
}

.TargetProfileSlider {
  width: 100%;
  margin-top: 20px;
}

.TargetProfile .SearchContainer {
  margin: 20px;
}

.TargetProfile .SearchContainer .NewObject {
  width: 8.5rem;
}

.TargetProfile .OptionsContainer {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  margin-top: -20px !important;
}

.TargetProfile .OptionsContainer li {
  float: left;
  width: 25%;
}

.TargetProfile .OptionsContainer li a {
  display: block;
  background-color: #1d2635;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  /* border-right: 2px solid rgb(158, 158, 158);
  border-bottom: 2px solid rgb(158, 158, 158); */
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.TargetProfile .OptionsContainer li a:hover {
  background-color: #324ab2;
  color: white;
}

.TargetProfile .SettingsCard {
  border-radius: 5px;
  padding: 50px;
  margin-top: 40px;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.TargetProfile .SettingsCard .SettingsCard-Content {
  margin-top: 40px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateInfo {
  margin-top: 50px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateInfo .UpdateInfo-Header {
  font-size: 20px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateInfo .UpdateInfo-Body {
  font-weight: 500;
  width: 90%;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateImage {
  margin-top: 50px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateImage .UpdateImage-Header {
  font-size: 20px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .UpdateImage .UpdateImage-Body {
  font-weight: 500;
  width: 90%;
}

.TargetProfile .SettingsCard .SettingsCard-Content .DeleteOperation .DeleteOperation-Header {
  font-size: 20px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .DeleteOperation .DeleteOperation-Body {
  font-weight: 500;
  width: 90%;
}

.TargetProfile .SettingsCard .SettingsCard-Content .SwitchState {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TargetProfile .SettingsCard .SettingsCard-Content .SwitchState .SwitchState-Header {
  font-size: 20px;
}

.TargetProfile .SettingsCard .SettingsCard-Content .SwitchState .SwitchState-Body {
  font-weight: 500;
  width: 90%;
}

.NewNoteButton,
.NewRelationButton {
  height: 60px;
  width: 100%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
}

/* TargetProfile-end */

/* NoteCard */

.NoteCardContainer {
  width: 49%;
  text-align: center;
  height: auto;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 0px;
  border: #e0e0e0 1px solid;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.NoteCardContainer hr {
  width: 100%;
  color: rgba(43, 42, 42, 0.514);
}

.NoteTitle {
  display: flex;
  align-items: center;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: black;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.NoteTitle img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100px;
  border: none;
  margin-right: 12px;
}

.NoteTitle p:nth-of-type(1) {
  margin: 5px 0px 0px 0px !important;
  color: #1d2635;
  font-weight: 500;
  padding-left: 5px;
}

.NoteTitle p:nth-of-type(2) {
  margin: 5px 0px 0px 0px !important;
  font-weight: 500;
  font-size: 12px;
  padding-left: 10px;
}

.NoteContent {
  text-align: left;
  padding: 20px;
  font-size: 13px;
  height: 130px;
}

.NoteContent a,
.NoteModalContent-File {
  font-size: 16px;
  text-decoration: none;
}

.NoteModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoteModalContent .NoteModalContent-Img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100px;
  margin-top: 20px;
}

.NoteModalContent .NoteModalContent-AuthData {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoteModalContent .NoteModalContent-AuthData p {
  margin-block-end: 0px !important
}

.NoteModalContent .NoteModalContent-AuthName {
  color: #1d2635;
}

.NoteModalContent .NoteModalContent-CreateDate {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.NoteModalContent .NoteModalContent-Title {
  color: #1d2635;
}

.NoteModalContent .NoteModalContent-Description {
  font-weight: 500;
}

.BtnNote,
.BtnTask {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
  transition: 0.2s;
}

.TargetProfile .RelationSlide {
  height: auto;
  padding-top: 20px;
}

/* NoteCard-end */

/* ToolCard */

.ToolCardContainer {
  width: 48%;
  text-align: center;
  height: auto;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 5px;
  border: #e0e0e0 1px solid;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.ToolCardsContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ToolContent {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  height: 130px;
}

.ToolContent a,
.ToolModalContent-File {
  font-size: 16px;
  text-decoration: none;
}

.ToolModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.ToolModalContent .ToolModalContent-Img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100px;
  margin-top: 20px;
}

.ToolModalContent .ToolModalContent-AuthData {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ToolModalContent .ToolModalContent-Description {
  font-weight: 500;
}

.BtnTool,
.BtnTask {
  height: 60px;
  width: 90%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
  transition: 0.2s;
}

/*
.TargetProfile .RelationSlide {
  height: auto;
  padding-top: 20px;
} */

/* ToolCard-end */

/* Operation profile */

.OperationProfile {
  height: auto;
  width: 100%;
}

.OperationProfileContent {
  margin: auto;
  width: 90%;
  margin-top: 30px;
}

.PostsSlide {
  height: auto;
}

.OperationProfile .OperationProfileInfo {
  width: 100%;
  background-color: #000000;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.OperationProfile .OperationProfileInfo ul {
  font-size: 15px;
}

.OperationProfile .OperationProfileInfo ul li:nth-of-type(1) {
  font-size: 23px;
}

.OperationProfile .OperationProfileInfo ul li:nth-of-type(2) {
  font-size: 18px;
}

.OperationProfile .OperationProfileInfo ul li:nth-of-type(3) {
  margin-top: 10px;
}



.OperationProfile .OperationProfileHeader {
  height: 300px;
  display: flex;
  border: 2px solid #c7c7c767;
  border-radius: 5px;
}

.OperationProfile .OperationProfileInfo ul {
  list-style: none;
  font-weight: 500;
}

.OperationProfileSlider {
  width: 100%;
  margin-top: 20px;
}

.OperationProfileSlider .profile-slider-btn {
  display: inline-block;
  margin-left: 10px;
  width: 30%;
  padding: 15px;
  text-align: center;
  font-size: 15px;
}

.OperationProfile .SearchContainer {
  margin: 20px;
}

.OperationProfile .SearchContainer .NewObject {
  width: 8.5rem;
}

.OperationProfile .TargetsSlide {
  height: auto;
  padding-top: 20px;
}

.OperationProfile .MembersSlide .MembersContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfoContainer {
  width: 49%;
  max-width: 600px;
  display: flex;
  justify-content: center;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfo {
  margin-bottom: 100px;
  width: 95%;
  position: relative;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfo .MemberDottedIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfo .MemberDottedIcon .DropDownToggle {
  font-size: 18px;
  background-color: transparent;
  border: none;
  color: black;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfo .MemberDottedIcon .DropDownToggle::after {
  display: none;
}

.OperationProfile .MembersSlide .MembersContainer .profileCardInfo .MemberDottedIcon .DropDownToggle:hover {
  background-color: transparent;
}

.SetAdminContainer {
  display: flex;
  align-items: center;
}

.SetAdminContainer .SetAdminContainer-text {
  display: flex;
  flex-direction: column;
}

.SetAdminContainer .SetAdminContainer-text .SetAdminHeader {
  font-size: 20px;
  margin-right: auto;
  justify-content: center;
  margin-block-end: 0em !important;
}

.SetAdminContainer .SetAdminContainer-text .SetAdminBody {
  font-size: 13px;
  font-weight: 500;
  width: 70%;
}

.SetAdminContainer .form-switch {
  margin-left: auto;
}

.OperationProfile .SettingsCard {
  border-radius: 5px;
  min-height: 150vh;
  padding: 50px;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.OperationProfile .SettingsCard .SettingsCard-Header {
  font-size: 25px;
  color: #324ab2;
}

.OperationProfile .SettingsCard .SettingsCard-Content {
  margin-top: 40px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .AddMember .AddMember-Header {
  font-size: 20px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .AddMember .AddMember-Body {
  font-weight: 500;
}

.OperationProfile .SettingsCard .SettingsCard-Content .SwitchState {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.OperationProfile .SettingsCard .SettingsCard-Content .SwitchState .SwitchState-Header {
  font-size: 20px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .SwitchState .SwitchState-Body {
  font-weight: 500;
  width: 90%;
}

#SwitchState-SwitchButton {
  font-size: 1.5rem;
  transition: background-position .15s ease-in-out !important;
}

#SwitchState-SwitchButton:checked {
  background-color: #18202D;
  border: none;
}

#SwitchState-SwitchButton:focus {
  border: none;
  box-shadow: 0 0 0 0.25rem rgba(25, 28, 32, 0.25);
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateInfo {
  margin-top: 50px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateInfo .UpdateInfo-Header {
  font-size: 20px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateInfo .UpdateInfo-Body {
  font-weight: 500;
  width: 90%;
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateImage {
  margin-top: 50px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateImage .UpdateImage-Header {
  font-size: 20px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .UpdateImage .UpdateImage-Body {
  font-weight: 500;
  width: 90%;
}

.OperationProfile .SettingsCard .SettingsCard-Content .DeleteOperation .DeleteOperation-Header {
  font-size: 20px;
}

.OperationProfile .SettingsCard .SettingsCard-Content .DeleteOperation .DeleteOperation-Body {
  font-weight: 500;
  width: 90%;
}

.OperationProfile .Post {
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid #e1e1e1c4;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.OperationProfile .Post .PostAuthContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.OperationProfile .Post .PostAuthImage {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border-right: none;
  object-fit: cover;
  margin-right: 12px;
}

.OperationProfile .Post .PostAuthContainer p {
  margin: 5px 0px !important;
}

.OperationProfile .Post .PostAuthContainer p:nth-of-type(2) {
  font-weight: 400;
  font-size: 13px;
}

.OperationProfile .Post .PostAuthContainer .PostDeleteIcon {
  margin-left: auto;
  margin-right: 20px;
}

.OperationProfile .Post .PostAuthContainer .PostDeleteIcon img {
  width: 22px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.OperationProfile .Post .PostAuthContainer .PostDeleteIcon img:hover {
  transform: translateY(-5px);
}

.OperationProfile .Post .Postauthor {
  margin: 5px;
}

.OperationProfile .Post .PostTitle {
  font-size: 20px;
  color: #18202D;
}

.OperationProfile .Post .PostContent {
  color: rgb(88, 88, 88);
  margin: 5px;
}

.OperationProfile .Post .PostFile {
  font-size: 16px;
  text-decoration: none;
}

.OperationProfile .Post hr {
  width: 100%;
  color: rgba(43, 42, 42, 0.514);
}

.OperationProfile .Post .PostInputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.OperationProfile .Post .PostInputContainer .PostInput {
  width: 95%;
  height: 40px;
  padding: 10px;
  border-radius: 100px;
  background-color: rgba(185, 185, 185, 0.297);
}

.OperationProfile .Post .PostInputContainer .PostInputIcon {
  width: 16px;
  position: absolute;
  right: 50px;
  bottom: 25%;
  cursor: pointer;
  transition: 0.2s;
}

.OperationProfile .Post .PostComments {
  color: rgb(88, 88, 88);
  margin: 5px;
  font-size: 13px;
}

.OperationProfile .OptionsContainer {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background-color: rgb(251, 251, 251);
  margin-top: -20px !important;
}

.OperationProfile .OptionsContainer li {
  float: left;
  width: 25%;
}

.OperationProfile .OptionsContainer li a {
  display: block;
  background-color: #1d2635;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  /* border-right: 2px solid rgb(158, 158, 158);
  border-bottom: 2px solid rgb(158, 158, 158); */
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.OperationProfile .OptionsContainer li a:hover {
  background-color: #324ab2;
  color: white;
}

.dropdown {
  margin: 5px 0px;
}

.NewTargetButton,
.NewTaskButton,
.NewPostButton,
.NewMemberButton,
.UpdateInfoButton,
.NewToolButton,
.UpdateImageButton {
  height: 60px;
  width: 100%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
  transition: 0.2s;
}

.DeleteOperationButton {
  height: 60px;
  width: 100%;
  background-color: #fb2323;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: 30px 0;
  transition: 0.2s;
}

/* end operation profile */

/* post comments */

.OperationProfile .Comment {
  border: 1px solid #e1e1e1c4;
  border-radius: 5px;
  padding: 7px;
  margin-bottom: 15px;
}

.OperationProfile .Comment .CommentAuthContainer {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.OperationProfile .Comment .CommentAuthImage {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border-right: none;
  object-fit: cover;
  margin-right: 12px;
}

.OperationProfile .Comment .CommentAuthContainer p:nth-of-type(2) {
  font-weight: 400;
  font-size: 13px;
  padding-left: 12px;
}

.OperationProfile .Comment .CommentAuthContainer .CommentDeleteIcon {
  margin-left: auto;
  margin-right: 15px;
}


.OperationProfile .Comment .CommentAuthContainer .CommentDeleteIcon img {
  width: 18px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.OperationProfile .Comment .CommentAuthContainer .CommentDeleteIcon img:hover {
  transform: translateY(-5px);
}


.OperationProfile .Post .CommentAuthContainer .CommentDottedIcon .DropDownToggle::after {
  display: none;
}

.OperationProfile .Comment .Commentauthor {
  margin: 5px 0px !important;
}

.OperationProfile .Comment .Commentauthor:nth-of-type(2) {
  padding-left: 0px !important;
}

/* end post comments */

.TaskCardContainer {
  /* max-width: 400px; */
  width: -moz-available;
  width: -webkit-fill-available;
  text-align: center;
  height: auto;
  border-radius: 5px;
  display: inline-block;
  margin: 10px 10px;
  border: #e0e0e0 1px solid;
  background-color: white;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.TaskTitle {
  display: flex;
  align-items: center;
  font-weight: 500;
  border: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: black;
  padding: 10px;
  margin: 0;
  text-align: center;
}

.TaskCardContainer hr {
  width: 100%;
  color: rgba(43, 42, 42, 0.514);
}

.TaskTitle img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  border-right: none;
  margin-right: 12px;
}

.TaskTitle p {
  margin: 5px 0px !important;
}

.TaskTitle p:nth-of-type(2) {
  font-weight: 400;
  font-size: 13px;
  color: #8a97a0;
}

.TaskContent {
  text-align: center;
  padding: 10px;
  font-size: 13px;
}

.BtnTask {
  width: 80%;
  cursor: pointer;
  color: white;
  border: none;
  background-color: #18202D;
  padding: 10px 30px;
  border-radius: 5px;
  margin: 20px 0px;
}

.TaskAgent {
  text-align: left;
  color: rgb(88, 88, 88);
  padding: 0px 10px;
  font-size: 13px;
}

.TaskDottedIcon {
  margin-left: auto;
}

.TaskDottedIcon .DropDownToggle {
  font-size: 18px;
  background-color: transparent;
  border: none;
  color: black;
}

.TaskDottedIcon .DropDownToggle::after {
  display: none;
}

.TaskDottedIcon .DropDownToggle:hover {
  background-color: transparent;
}

.TaskModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TaskModalContent .TaskModalContent-Img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 100px;
  margin-top: 20px;
}

.TaskModalContent .TaskModalContent-Type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.TaskModalContent .TaskModalContent-Type p {
  margin-block-end: 0px !important
}

.TaskModalContent .TaskModalContent-Type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.TaskModalContent .TaskModalContent-Type p {
  margin-block-end: 0px !important
}

.TaskModalContent .TaskModalContent-AuthData {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.TaskModalContent .TaskModalContent-AuthData img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.TaskModalContent .TaskModalContent-AuthData p {
  font-size: 14px;
  margin-block-end: 0px !important
}

.TaskModalContent .TaskModalContent-AuthData p:nth-of-type(2) {
  font-size: 12px;
  font-weight: 400;
}

/* .TaskModalContent .TaskModalContent-AuthName {
  color: #1d2635;
}

.TaskModalContent .TaskModalContent-CreateDate {
  color: rgb(140, 140, 140);
  font-weight: 500;
} */

.TaskModalContent .TaskModalContent-Title {
  color: #1d2635;
}

.TaskModalContent .TaskModalContent-Description {
  font-weight: 500;
}

.SettingsModal-Container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
}

.SettingsModal-Container div {
  width: 50%;
}

.SettingsModal-Container h4 {
  font-size: 19px;
}

.SettingsModal-Container p {
  font-weight: 400;
  font-size: 15px;
}

.SettingsModal-Container button {
  display: block;
  margin-left: auto;
  margin-top: 30px;
  width: 40%;
  height: 50px;
}

/* Operation profile end */

/*Login page*/

.Login *,
*::before,
*::after {
  box-sizing: border-box;
}

.ErrorMessage {
  color: rgb(252, 77, 77);
  text-align: center;
}

/* .Login h1 {
  font-weight: 500;
  color: #384047;
  line-height: 1.5em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
} */

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoContainer img {
  max-width: 220px;
  max-height: 30px;
  margin: 30px;
  width: 90%;
}

.Login a {
  font-size: 0.98em;
  color: #8a97a0;
  text-decoration: none;
}

.Login a:hover {
  color: green;
}

.ModalInput,
.ModalInput:focus {
  color: #384047;
  background-color: #e8eeef;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
  border: none;
  border-radius: 4px;
  padding: 0.7em;
  margin-bottom: 1.2em;
  width: 100%;
}

.Login {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  padding: 6%;
  margin: 0;
}

.Login form {
  background-color: #fff;
  padding: 3em;
  border-radius: 8px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.Login form .inputLoginContainer {
  position: relative;
}

.Login form .inputLoginContainer img {
  position: absolute;
  left: 10px;
  bottom: 50%;
  width: 20px;
}

.Login form .inputLoginContainer #textarea {
  bottom: 65%;
}

.Login form input,
.Login form textarea {
  color: #384047;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.208);
  border-radius: 10px;
  padding: 1em;
  padding-left: 40px;
  margin-bottom: 1.2em;
  width: 100%;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
}

.Login form input:focus,
.Login form textarea:focus {
  outline: none;
}

.Login .LoginButton {
  font-weight: 600;
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #18202D;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 0.8em;
  margin-top: 1em;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.Login .LoginButton:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.Login .LoginButton span {
  position: relative;
  z-index: 1;
}

.Login .LoginButton .circle {
  position: absolute;
  z-index: 0;
  background-color: #35a556;
  border-radius: 50%;
  transform: scale(0);
  opacity: 5;
  height: 50px;
  width: 50px;
}

.Login .LoginButton .circle.animate {
  animation: grow 0.5s linear;
}

.Login form .LinkContainer {
  display: flex;
  justify-content: center;
}

.Login form .LinkContainer p {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.Login form .LinkContainer a {
  text-decoration: none;
  color: #1c2b70;
}

.Login form .LinkContainer a:hover {
  color: #141f50;
}

/* pagination */

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  width: 100%;
  margin-top: 15px;
}

.pagination a,
.pagination span {
  border: none;
  text-align: center;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.8);
  background: rgba(255, 255, 255, 0);
  margin-right: 4px;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-shadow: 0 10px 27px -20px rgb(0 0 0 / 33%);
  -moz-box-shadow: 0 10px 27px -20px rgba(0, 0, 0, .33);
  box-shadow: 0 10px 27px -20px rgb(0 0 0 / 33%);
}

.pagination a.active {
  background-color: #000000;
  color: white;
  border-radius: 3px;
}

/* end pagination */

/* profile */

.profilePage-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 80px;
  height: auto;
}

.EditProfileButton {
  height: 60px;
  width: 35%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin-left: auto;
  transition: 0.2s;
}

.EditProfileData-Button {
  height: 50px;
  width: 35%;
  background-color: #18202D;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
}

.profileCardInfo {
  width: 35%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px 20px 20px;
  border-radius: 20px;
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.531);
}

.profileCardInfo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}

.profileCardInfo-content p {
  height: 10px;
}

#labelInputFile {
  position: relative;
  bottom: 60px;
}

.profileCardInfo-image {
  height: 130px;
  width: 130px;
  border-radius: 80px;
  object-fit: cover;
}

#labelInputFile .overlay {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background-color: #00000047;
  opacity: 0;
  transition: 0.2s ease-in-out;
}

#labelInputFile .overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.ProfilePage-inputsContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProfilePage-inputsContainer .ProfilePage-inputsGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 30px;
}

.ProfilePage-inputsContainer .ProfilePage-inputsGroup input {
  border: none;
  border-bottom: 1px solid rgb(153 153 153);
  border-radius: 0;
  width: 80%;
}

.ProfilePage-Modal {
  display: flex !important;
  align-items: center;
  overflow-y: hidden;
}

.ProfilePage-Modal .modal-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.ProfilePage-Modal .modal-dialog .modal-content {
  border-radius: 20px;
}

/* .profileCardInfo-imageContainer {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */



/* .profileCardInfo-fileInput {
  width: 100%;
}

.profileCardInfo-inputsContainer {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileCardInfo .profileCardInfo-inputsContainer .inputs-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  margin: 15px 0px;
}

.profileCardInfo .profileCardInfo-inputsContainer .inputs-group input {
  width: 100%;
  border-radius: 4px;
} */

/* end profile */

/* profiles navigation bar */

.ProfilesNavigationBar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.ProfilesNavigationBar .ProfilesNavigationBar-container {
  display: flex;
  width: 100%;
  height: 50%;
}

.ProfilesNavigationBar .ProfilesNavigationBar-containerOperation button {
  width: 20%;
}

.ProfilesNavigationBar .ProfilesNavigationBar-container button {
  width: 25%;
  padding: 15px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: rgb(67, 67, 67);
  border-bottom: 1px solid rgb(190, 190, 190);
}

.ProfilesNavigationBar .ProfilesNavigationBar-container button.active {
  border-bottom: 3px solid #324ab2;
}

.ProfilesNavigationBar .ProfilesNavigationBar-container button img {
  margin-right: 15px;
}

.ProfilesNavigationBar .ProfilesNavigationBar-container button svg {
  margin-right: 15px;
  color: rgb(100, 100, 100);
}

.ProfilesNavigationBar::-webkit-scrollbar {
  margin-top: 10px;
  height: 5px;
}

/* Track */
.ProfilesNavigationBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ProfilesNavigationBar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ProfilesNavigationBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end profiles navigation bar */

/* footer */

footer {
  width: calc(100% - 80px);
  margin-left: auto;
}

/* end footer */

/* drag and drop */

.dragAndDrop label {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: 150px;
  min-width: 0px;
  max-width: none;
  width: 100%;
  margin-top: -0.5rem;
}

.dragAndDrop label svg {
  display: none;
}

.dragAndDrop label div {
  display: flex;
  justify-content: center;
}

.dragAndDrop label div span:nth-of-type(2) {
  display: none;
}

/* end drag and drop */

/* modal buttons */

.ModalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ModalButtons button {
  height: 50px;
  width: 48%;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  transition: 0.2s;
}

.ModalButtons .OkButton {
  background-color: #18202D;
  color: white;
}

.ModalButtons .DeleteButton {
  background-color: #fb2323;
  color: white;
}

.ModalButtons .CancelButton {
  background-color: rgb(240, 240, 240);
  color: black;
  -webkit-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 5px 10px -4px rgba(0, 0, 0, 0.2);
}

.ModalButtons .CloseButtonTask {
  width: 100%;
}

/* end modal buttons */

/* about */

.AboutPage-Container {
  padding: 30px;
}

.AboutPage-block {
  font-weight: 500;
  margin: 70px 0px;
}

.AboutPage-Copyright {
  position: absolute;
  bottom: 0;
  left: 50%;
  font-size: 12px;
  color: rgba(0, 0, 0, .75);
}

/* end about */

/* contact */

.Contact {
  display: flex;
  -webkit-display: box;
  -moz-display: box;
  -ms-display: flexbox;
  -webkit-display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  padding: 6%;
  margin: 0;
}

.Contact form {
  background-color: #fff;
  padding: 3em;
  border-radius: 8px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 10px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.Contact form .inputContactContainer {
  position: relative;
}

.Contact form .inputContactContainer img {
  position: absolute;
  left: 10px;
  bottom: 50%;
  width: 20px;
}

.Contact form .inputContactContainer #textarea {
  bottom: 65%;
}

.Contact form input,
.Contact form select,
.Contact form textarea {
  color: #384047;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.208);
  border-radius: 10px;
  padding: 1em;
  padding-left: 40px;
  margin-bottom: 1.2em;
  width: 100%;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.33);
}

.Contact form select {
  padding: 1em 10px;
}

.Contact form input:focus,
.Contact form textarea:focus {
  outline: none;
}

.Contact .ContactButton {
  font-weight: 600;
  text-align: center;
  font-size: 19px;
  color: #fff;
  background-color: #18202D;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 0.8em;
  margin-top: 1em;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
}

.Contact .ContactButton:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-4px);
}

.Contact .ContactButton span {
  position: relative;
  z-index: 1;
}

.Contact .ContactButton .circle {
  position: absolute;
  z-index: 0;
  background-color: #35a556;
  border-radius: 50%;
  transform: scale(0);
  opacity: 5;
  height: 50px;
  width: 50px;
}

.Contact .ContactButton .circle.animate {
  animation: grow 0.5s linear;
}

.Contact form .LinkContainer {
  display: flex;
  justify-content: center;
}

.Contact form .LinkContainer p {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.Contact form .LinkContainer a {
  text-decoration: none;
  color: #1c2b70;
}

.Contact form .LinkContainer a:hover {
  color: #141f50;
}

/* end contact */

.NoDataHeader-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
}

.NoDataHeader-Content {
  width: 70%;
  font-size: 30px;
  line-height: 2em;
  margin-bottom: 20px;
}

.NoDataHeader-Container img {
  width: 100px;
}

/* react kanban */

.react-kanban-board [data-rbd-droppable-id="board-droppable"]:nth-of-type(1) {
  white-space: initial !important;
  display: flex;
  width: 100%;
}

.react-kanban-column {
  background-color: #f4f5f794 !important;
  width: -moz-available;
  width: -webkit-fill-available;
}

.react-kanban-column-header {
  color: #5e6c84;
  font-weight: 500 !important;
  font-size: 15px;
}


.react-kanban-board [aria-describedby="rbd-hidden-text-1-hidden-text-1"] div:nth-child(1) {
  display: block !important;
}

/* end react kanban */

/* hover animations */

.sideBar-footer a:hover {
  background-color: #d2e2fd6c;
}

.OperationCardDashboardContainerButton .OperationCardDashboardContainerButton-button:hover,
.TargetCardDashboardContainerButton .TargetCardDashboardContainerButton-button:hover,
.recentTargets-container .recentTargetsCard-container .targetCard .targetCard-buttonContainer .targetCard-button:hover,
.NewOperationButton:hover,
.EditProfileButton:hover,
.ModalButtons .OkButton:hover,
.BtnTask:hover,
.BtnNote:hover,
.BtnTool:hover,
.NewPostButton:hover,
.NewTargetButton:hover,
.NewTaskButton:hover,
.NewMemberButton:hover,
.UpdateInfoButton:hover,
.NewToolButton:hover,
.UpdateImageButton:hover {
  scale: 1.03;
  background-color: #242f3f;
}

.ModalButtons .DeleteButton:hover,
.DeleteOperationButton:hover {
  scale: 1.03;
  background-color: #c71c1c;
}

.ModalButtons .CancelButton:hover {
  scale: 1.03;
  background-color: rgb(206, 206, 206);
}

.OperationProfile .Post .PostInputContainer .PostInputIcon:hover {
  scale: 1.4;
}

/* end hover animations */

@media screen and (min-width: 1218px) {
  .ToolCardContainer {
    max-width: 300px;
  }

  .ToolCardsContainer {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1218px) {
  .react-kanban-board [data-rbd-droppable-id="board-droppable"]:nth-of-type(1) {
    flex-direction: column;
  }

  .TaskCardContainer {
    max-width: none;
    width: 97%;
  }

  .react-kanban-board {
    width: 100%;
    display: block !important;
  }

  .react-kanban-column {
    width: 100%;
  }
}

@media screen and (max-width: 1115px) {
  .OperationCard-name {
    font-size: 2.5vw;
  }

  .ProfilesNavigationBar .ProfilesNavigationBar-container button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .ProfilesNavigationBar .ProfilesNavigationBar-container button img {
    margin-right: 0px;
  }

  .ProfilesNavigationBar .ProfilesNavigationBar-container button svg {
    margin-right: 0px;
  }

  .SearchContainer button,
  input,
  select {
    font-size: 0.8rem;
  }
}

@media (max-width: 900px) {

  .userInfoCards-Container {
    width: 100%;
  }

  .OperationDescription {
    font-size: 15px;
  }

  .TargetDescription {
    font-size: 15px;
  }

  .dashboard-buttonsSection button div {
    width: 90%;
  }

  .NoDataHeader-Content {
    width: 100%;
  }
}

@media (max-width: 755px) {

  .NoteCardContainer {
    width: 100%;
  }

  .ToolCardContainer {
    width: 100%;
    max-width: initial;
  }

  .Login form {
    width: 95% !important;
    padding: 2rem;
  }

  .Contact form {
    width: 95% !important;
    padding: 2rem;
  }
}

@media (max-width: 700px) {
  .nav-links {
    position: absolute;
    right: 0;
    height: 200vh;
    width: 50%;
    top: 69px;
    background-color: #1d2635;
    z-index: 10;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: transform ease-in-out 0.3s;
  }

  .nav-ham-container {
    display: block;
    margin-right: 15px;
    margin-left: -15px;
  }

  .navActive {
    transform: translateX(0%);
  }

  .user-card {
    padding: 5px;
    width: 100px;
  }

  .user-img-top-bar {
    margin-right: 10px;
  }

  .user-name {
    font-size: 11px;
  }

  .OperationCardContainer {
    width: 100% !important;
    margin-left: initial;
    padding: 15px !important;
  }

  .OperationProfile .OperationProfileHeader {
    height: 270px;
  }

  .OperationProfile .OperationProfileInfo ul {
    font-size: 14px;
  }

  .OperationProfile .OperationProfileInfo ul li:nth-of-type(1) {
    font-size: 20px;
  }

  .OperationProfile .OperationProfileInfo ul li:nth-of-type(2) {
    font-size: 16px;
  }

  .userRecentAccess-Container {
    flex-wrap: wrap;
  }

  .profileCardInfo-imageContainer {
    width: 100%;
    height: 40%;
  }

  .profileCardInfo-inputsContainer {
    width: 100%;
    height: 60%;
  }

  .profileCardInfo-fileInput {
    width: auto;
  }

  .TargetCardContainer {
    width: 100% !important;
    margin-left: initial;
    padding: 15px !important;
  }

  .TargetProfile .TargetProfileInfo ul {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  footer {
    font-size: 14px;
  }

  .pageHeader .pageHeader-icon {
    font-size: 55px;
  }

  .pageHeader .pageHeader-title {
    margin-left: 10px;
  }

  .pageHeader .pageHeader-title h1 {
    font-size: 25px;
  }

  .pageHeader .pageHeader-title p {
    font-size: 12px;
  }

  .pageHeader .pageHeader-title-withButton {
    display: flex;
    flex-direction: column;
  }

  .pageHeader .pageHeader-title-withButton button {
    margin-left: initial;
    width: 100%;
    margin-top: 20px;
  }

  .OperationCard-container .OperationDescription .OperationDescription-description {
    font-size: 13px;
  }

  .OperationCardDashboard-container img {
    width: 100%;
  }

  .OperationCardDashboardContainerStatus .OperationCardDashboardContainerStatus-container {
    align-items: start;
    flex-direction: column;
    background-color: transparent;
  }

  .OperationCardDashboardContainerStatus .OperationCardDashboardContainerStatus-container div {
    flex-direction: row;
    gap: 20px;
  }

  .OperationCardDashboard-container #UnknownLabel {
    top: 200px;
    right: 25px;
  }

  .OperationCard-name {
    font-size: 20px;
  }

  .OperationProfile .OperationProfileHeader {
    height: 210px;
  }

  .OperationProfile .OptionsContainer li a {
    padding: 14px 0px;
    font-size: 14px;
  }

  .OperationProfile .OptionsContainer li a svg {
    font-size: 11px;
    margin-left: 2px !important;
  }

  .OperationProfile .OperationProfileInfo ul {
    font-size: 11px;
  }

  .OperationProfile .OperationProfileInfo ul li:nth-of-type(1) {
    font-size: 16px;
  }

  .OperationProfile .OperationProfileInfo ul li:nth-of-type(2) {
    font-size: 13px;
  }

  .SettingsModal-Container div {
    width: 95%;
  }

  .SettingsModal-Container h4 {
    font-size: 18px;
  }

  .SettingsModal-Container p {
    font-size: 14px;
  }

  .SettingsModal-Container button {
    margin-right: auto;
    margin-left: 5px;
    margin-top: 0px;
    width: 90%;
  }

  .OperationProfile .MembersSlide .MembersContainer .profileCardInfoContainer {
    width: 100%;
  }

  .OperationProfile .MembersSlide .MembersContainer .profileCardInfo {
    width: 95%;
  }

  .OperationProfile .SettingsCard {
    padding: 25px;
  }

  .DashboardHr {
    display: inline-block;
  }

  .TargetCardDashboard-container img {
    width: 100%;
  }

  .TargetCardDashboardContainerStatus .TargetCardDashboardContainerStatus-container {
    background-color: transparent;
    flex-direction: column;
    align-items: flex-start;
  }

  .TargetCardDashboardContainerStatus .TargetCardDashboardContainerStatus-container div {
    flex-direction: row;
    gap: 20px;
  }

  .TargetCardDashboardContainerButton .TargetCardDashboardContainerButton-button {
    width: 90%;
  }

  .userTargets-recent h4 {
    margin-left: initial;
  }

  .TargetProfileSlider .btns-container {
    width: 100%;
  }

  .TargetProfileSlider .profile-slider-btn {
    padding: 10px;
  }

  .TargetProfile .OptionsContainer li a {
    padding: 14px 0px;
    font-size: 14px;
  }

  .TargetProfile .OptionsContainer li a svg {
    font-size: 11px;
    margin-left: 2px !important;
  }

  .TargetProfile .TargetProfileHeader {
    height: 210px;
  }

  .TargetProfile .TargetProfileInfo ul {
    font-size: 11px;
  }

  .TargetProfile .TargetCardDashboard-container img {
    width: 100%;
  }

  .OperationProfile .OperationCardDashboard-container img {
    width: 100%;
  }

  .OperationProfile .OperationProfileInfo ul {
    padding-left: 1rem;
  }

  .TargetProfile .TargetProfileInfo ul {
    padding-left: 1rem;
  }

  .TargetProfile .SettingsCard {
    padding: 25px;
  }

  .dashboard {
    margin: 10px 15px;
  }

  .NoDataHeader-Content {
    font-size: 22px;
  }

  .NoDataHeader-Container img {
    width: 80px;
  }

  .dashboard-buttonsSection {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-buttonsSection button {
    width: 90%;
    margin: 20px;
  }

  .dashboard-buttonsSection button div {
    width: 80%;
  }

  .recentPosts-container .recentPostsCard-container .postCard {
    width: 100%;
    margin-bottom: 20px;
  }

  .recentTargets-container .recentTargetsCard-container .targetCard {
    width: 100%;
    margin-bottom: 20%;
  }


  .user-card {
    margin-bottom: 30px;
    padding: 0px;
  }

  .user-name {
    display: inline-block;
  }

  .user-card-nameDiv {
    display: none;
  }

  .user-name p {
    display: none;
  }

  .recentPosts-container,
  .recentTargets-container {
    margin-left: 0px;
  }

  .cudUqG,
  .jRqwJi {
    width: 300px;
  }

  .profileCardInfo {
    width: 80%;
  }

  .ProfilePage-inputsContainer .ProfilePage-inputsGroup input {
    width: 90%;
  }

  .ProfilesNavigationBar .ProfilesNavigationBar-container button {
    font-size: 0px;
  }
}

@media screen and (max-width: 430px) {

  .OperationProfile .OptionsContainer li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .OperationProfile .OptionsContainer li a svg {
    display: block;
    font-size: 15px;
    margin: 5px 0px;
  }

  .OperationProfile .Post .PostAuthContainer p,
  .OperationProfile .Comment .Commentauthor {
    font-size: 12px;
  }

  .OperationProfile .Post .PostAuthContainer .PostDeleteIcon img,
  .OperationProfile .Comment .CommentAuthContainer .CommentDeleteIcon img {
    width: 13px;
  }

  .OperationProfile .Comment .CommentAuthImage {
    width: 30px;
  }

  .OperationProfile .Comment .CommentAuthContainer .CommentDeleteIcon,
  .OperationProfile .Post .PostAuthContainer .PostDeleteIcon {
    margin-right: 10px;
  }

  .OperationProfile .Post .PostInputContainer .PostInputIcon {
    right: 25px;
  }

  .OperationProfile .Post .PostTitle {
    font-size: 15px;
  }

  .OperationProfile .Post .PostContent {
    font-size: 12px;
  }

  .TargetProfile .OptionsContainer li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .TargetProfile .OptionsContainer li a svg {
    display: block;
    font-size: 15px;
    margin: 5px 0px;
  }

  .NoDataHeader-Content {
    font-size: 20px;
  }

  .NoDataHeader-Container img {
    width: 60px;
  }

  .SearchContainer .SearchInputContainer svg {
    width: 20px;
  }

  .NoteTitle img {
    width: 70px;
  }

  .NoteTitle p:nth-of-type(1) {
    font-size: 14px;
  }

  .ToolTitle img {
    width: 70px;
  }

  .ToolTitle p:nth-of-type(1) {
    font-size: 14px;
  }

  .Login form {
    width: 97% !important;
    padding: 1.5rem;
  }
}

@keyframes grow {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}